<!--
 * @Description: 产品管理-入口
 * @Author: kecraft
 * @Date: 2024-01-03 10:52:53
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 14:55:50
 * @FilePath: /impact-iotos-console/src/views/product/index.vue
-->
<template>
  <div class="device-homePage">
    <deviceFlow />
    <div class="main-container">
      <!-- 面包屑 -->
      <div class="big-title">
        <div></div>
        产品管理
      </div>

      <!-- 查询框 -->
      <div class="search-container">
        <div class="form-item">
          <el-input v-model="query.keyword" class="input-item" placeholder="请输入产品名称或产品PK" :prefix-icon="Search"
            @keyup.enter="handleSearch" />
        </div>
        <div class="form-item">
          <el-select v-model="query.transferType" placeholder="请选择交互协议" style="margin-right:10px" class="input-item1"
            clearable @change="search">
            <el-option v-for="item in transferTypeList" :key="item.key" :label="item.label" :value="item.key" />
          </el-select>
          <el-select v-model="query.deviceType" placeholder="请选择设备类型" class="input-item1" clearable @change="search">
            <el-option v-for="item in deviceTypeList" :key="item.key" :label="item.label" :value="item.key" />
          </el-select>
        </div>
        <div class="form-item" style="flex: 1;">
          <div class="search-btn" @click="handleSearch" v-domPreventReClick>搜索</div>
          <div class="reset-btn" @click="handleReset" v-domPreventReClick>重置</div>
        </div>
        <div class="btn-groups">
          <el-upload action="" class="upload-demo" ref="upload" accept=".json" :limit="1" :show-file-list="false"
            :on-exceed="handleExceed" :http-request="changeUpload">
            <template #trigger>
              <div class="upload-btn">
                <img src="@/assets/icon/upload_icon.png" />
                导入产品
              </div>
            </template>
          </el-upload>
          <div class="right-btns">
            <div :class="tabSelect === 'card' ? 'isSelect' : ''" @click="handleTabChange('card')" v-domPreventReClick>
              <img src="@/assets/icon/left_icon_1.png" v-if="tabSelect === 'card'" />
              <img src="@/assets/icon/left_icon.png" v-else />
            </div>
            <div :class="tabSelect === 'list' ? 'isSelect' : ''" @click="handleTabChange('list')" v-domPreventReClick>
              <img src="@/assets/icon/right_icon_1.png" v-if="tabSelect === 'card'" />
              <img src="@/assets/icon/right_icon.png" v-else />
            </div>
          </div>
        </div>
      </div>

      <!-- 卡片 -->
      <div class="list-box" v-if="tabSelect === 'card'">
        <!-- 新增 -->
        <div class="add-box" @click="gotoAdd" v-domPreventReClick>
          <img src="@/assets/icon/add_product.png">
          <div>创建产品</div>
        </div>
        <div class="normal-box" v-for="(item, index) of productList" :key="index">
          <div class="box-top" @click="gotoDetails(item)" v-domPreventReClick>
            <div>
              <img src="@/assets/product/product_1.png" v-if="item.deviceType === 'GATEWAY'">
              <img src="@/assets/product/product_2.png" v-if="item.deviceType === 'TERMINAL'">
              <img src="@/assets/product/product_3.png" v-if="item.deviceType === 'GENERAL'">
              <img src="@/assets/product/product_4.png" v-if="item.deviceType === 'SWITCH'">
            </div>

            <div class="title-box">
              <div class="title-container">
                <span class="product-title">{{ item.name }}</span>
              </div>
              <span class="product-type">{{ item.deviceLinkTypeStr }}</span>
            </div>
          </div>
          <div class="box-form" @click="gotoDetails(item)" v-domPreventReClick>
            <div>
              <span>设备类型：</span>
              <span>{{ item.deviceTypeStr }}</span>
            </div>
            <div>
              <span>交互协议：</span>
              <span>{{ item.transferTypeStr }}</span>
            </div>
            <div>
              <span>数据格式：</span>
              <span>{{ item.dataFormatStr }}</span>
            </div>
            <div>
              <span>创建时间：</span>
              <span>{{ item.createdDate || "-" }}</span>
            </div>
          </div>
          <div class="box-bottom">
            <el-tooltip class="box-item" effect="dark" content="编辑" placement="bottom">
              <div @click="edit(item.pk)" v-domPreventReClick>
                <div></div>
              </div>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" content="删除" placement="bottom">
              <div @click="del(item)" v-domPreventReClick>
                <div></div>
              </div>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" content="导出" placement="bottom">
              <div @click="exportJson(item.pk, item.name)" v-domPreventReClick>
                <div></div>
              </div>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" content="设备管理" placement="bottom">
              <div @click="gotoDevice(item.pk)" v-domPreventReClick>
                <div></div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="tables" v-if="tabSelect === 'list'">
        <div class="btn-list-add" @click="gotoAdd" v-domPreventReClick>
          <img src="@/assets/icon/add_icon.png">
          创建产品
        </div>
        <el-table stripe :data="productList" empty-text="暂无数据" v-loading="isload" style="width: 100%;"
          @row-click="handleRowClick">
          <el-table-column width="80" label="序号" align="center">
            <template #default="scope">
              <span>{{
            scope.$index + 1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="设备名称" prop="name" align="center" show-overflow-tooltip />
          <el-table-column width="200" label="联网方式" prop="deviceLinkTypeStr" align="center">
            <template #default="scope">
              <div class="product-type-box">
                <span class="product-type">{{ scope.row.deviceLinkTypeStr }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="160" label="交互协议" prop="transferTypeStr" align="center" />
          <el-table-column min-width="120" label="数据格式" prop="dataFormatStr" align="center" />
          <el-table-column width="140" label="设备类型" prop="deviceTypeStr" align="center" />
          <!-- <el-table-column width="120" label="远程配置" prop="supportRemoteConfigStr" align="center" /> -->
          <el-table-column min-width="225" label="创建时间" prop="createdDate" align="center" />
          <el-table-column width="160" label="操作" align="center">
            <template #default="scope">
              <div class="column-btn">
                <el-tooltip class="box-item" effect="dark" content="编辑" placement="bottom">
                  <img src="@/assets/icon/edit.png" @click="edit(scope.row.pk, $event)" v-domPreventReClick>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="删除" placement="bottom" >
                  <img src="@/assets/icon/del.png" @click="del(scope.row, $event)" v-domPreventReClick>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="导出" placement="bottom">
                  <img src="@/assets/icon/improt.png" @click="exportJson(scope.row.pk, scope.row.name, $event)" v-domPreventReClick>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" content="设备管理" placement="bottom">
                  <img src="@/assets/icon/drvice.png" @click="gotoDevice(scope.row.pk, $event)" v-domPreventReClick>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer-conatiner">
        <paginationNew :total="total" @pageChange="getList" class="pagination" />
      </div>
    </div>

    <el-dialog v-model="isDel" title="删除" width="30%" :before-close="() => isDel = false">
      <div class="del-container">
        <div><el-icon color="rgb(251,125,55)" style="margin-right: 10px;">
            <WarningFilled />
          </el-icon>删除产品会删除产品信息，物模型及其他相关信息。</div>
        <div>
          <el-radio-group v-model="isUpload">
            <el-radio label="0">导出产品信息</el-radio>
            <el-radio label="1">不导出</el-radio>
          </el-radio-group>
        </div>

      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isDel = false">取消</el-button>
          <el-button type="primary" @click="handleDel">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import FileSaver from 'file-saver'
import { Search, WarningFilled } from '@element-plus/icons-vue';
import paginationNew from "@/components/control/paginationNew";
import deviceFlow from "@/components/flow/deviceFlow"
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
import {
  deviceLinkTypeList,
  transferTypeList,
  deviceTypeList,
  dataFormatList,
} from "@/hooks/productEnum";
import { enumFilter } from "@/hooks/common"
import api from '@/api/api'
const router = useRouter();
const query = reactive({
  page: 0,
  size: 11,
  transferType: "",
  deviceType: "",
  keyword: "",
})
const isUpload = ref("0"); // 删除是否导出 0:导出 1:不导出
const isDel = ref(false);
const total = ref(0);
const productList = ref([]);
const isload = ref(false);
const delPk = ref(null); // 删除的pk
const tabSelect = ref("card");
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
})
const handleTabChange = (v) => {
  if (tabSelect.value === v) return;
  tabSelect.value = v;
}

const handleReset = () => {
  query.transferType = query.deviceType = query.keyword = "";
  search()
}
const handleSearch = () => {
  query.page = 0
  query.size = 11
  search();
}
const search = () => {
  isload.value = true;
  api.productPage(query).then(res => {
    if (res.code === "0") {
      const { totalElements, content } = res.res.data;
      const list = content;
      list.forEach(item => {
        item.supportRemoteConfigStr = item.supportRemoteConfig ? '支持' : '不支持'
        item.deviceLinkTypeStr = enumFilter(item.deviceLinkType, deviceLinkTypeList) || "--"
        item.transferTypeStr = enumFilter(item.transferType, transferTypeList) || "--"
        item.deviceTypeStr = enumFilter(item.deviceType, deviceTypeList) || "--"
        item.dataFormatStr = enumFilter(item.dataFormat, dataFormatList) || "--"
      })
      total.value = totalElements;
      productList.value = list;
      isload.value = false;
    }
  })
};
search();
const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}

const handleRowClick = (v) => {
  gotoDetails(v)
}
const gotoAdd = () => {
  router.push("/product/product/add")
}

const edit = (pk, event) => {
  if (event) {
    event.stopPropagation();
  }
  router.push({
    path: "/product/product/add",
    query: { pk }
  })
}

const gotoDevice = (pk, event) => {
  if (event) {
    event.stopPropagation();
  }
  store.dispatch("vuexTest/changePkValue", pk);
  router.push({
    path: "/product/device/list",
  })
}
const gotoDetails = (product) => {
  const { name, pk } = product
  sessionStorage.removeItem("handleTabsSelect")
  router.push({
    path: "/product/product/details",
    query: { name, pk }
  })
}
const del = (item, event) => {
  if (event) {
    event.stopPropagation();
  }
  isUpload.value = '0';
  isDel.value = true;
  delPk.value = item;
}

const exportJson = (pk, name, event) => {
  if (event) {
    event.stopPropagation();
  }
  api.getProductDefinition(pk).then(res => {
    if (res.code === "0") {
      const json = res.res.data;
      const blob = new Blob([JSON.stringify(json)], { type: "application/json;charset=utf-8" });
      FileSaver.saveAs(blob, name + '.json')
      ElMessage.success("导出成功!")
    }
  })
}

const changeUpload = (e) => {
  const file = e.file
  const reader = new FileReader();
  reader.readAsText(file, "UTF-8");
  reader.onload = (fileReader) => {
    const fileData = fileReader.target.result;
    const json = JSON.parse(fileData)
    api.productImport(json).then(res => {
      if (res.code === "0") {
        ElMessage.success("导入成功!")
        search();
      }
    })
  };
}
const handleExceed = (e) => {
  currentInstance.proxy.$refs.upload.clearFiles();
  currentInstance.proxy.$refs.upload.handleStart(e[0]);
  currentInstance.proxy.$refs.upload.submit();
}
const handleDel = () => {
  const item = delPk.value
  if (isUpload.value === "0" && item) {
    exportJson(item.pk, item.name)
  }
  if (!item.pk) return;
  api.productDel(delPk.value.pk).then(({ code }) => {
    if (code == "0") {
      ElMessage.success("删除成功!")
      isDel.value = false;
      delPk.value = null;
      search()
    }
  })
}
</script>

<style lang="less" scoped>
.btn-list-add {
  width: 136px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;
  background: #30AE7D;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.btn-list-add:hover,
.btn-list-add:active {
  background: #56ba93;
}

.column-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    width: 13px;
    height: 13px;
    margin-right: 11px;
    cursor: pointer;
  }

  &>img:last-child {
    margin-right: 0;
  }
}

.product-type-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .product-type {
    display: inline-block;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    border-radius: 6px;
    border: 2px solid #E9934C;
    margin-left: 8px;
    font-weight: bold;
    font-size: 12px;
    color: #E9934C;
  }
}


.main-container {
  box-sizing: border-box;
  padding: 0 50px;

  .big-title {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #367CC5;
    margin-bottom: 30px;

    &>div {
      width: 3px;
      height: 17px;
      background: #367CC5;
      margin-right: 7px;
    }
  }
}

.list-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  &>div {
    width: 360px;
    height: 270px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 23px;
    margin-right: 24px;
    overflow: hidden;
  }

  // todo 如何添加边距
  &>div:nth-child(4n) {
    margin-right: 0;
  }

  .normal-box {
    background: #ffffff;
    border: 1px solid #ECECEC;
    box-shadow: 0px 0px 12px 0px rgba(216, 216, 216, 0.5);

    .box-top {
      cursor: pointer;
      box-sizing: border-box;
      padding: 29px 35px 0 35px;
      display: flex;
      align-items: center;

      &>div:first-child {
        width: 49px;
        height: 49px;
        margin-right: 16px;

        img {
          width: 100%;
        }
      }

      .title-container {
        display: flex;
        align-items: center;
      }

      .product-title {
        font-size: 16px;
        line-height: 22px;
        color: #333333;
        font-weight: bold;
        display: inline-block;
        max-width: 250px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: keep-all;
        margin-bottom: 6px;
      }

      .product-type {
        display: inline-block;
        padding: 1px 4px;
        border-radius: 6px;
        border: 2px solid #E9934C;
        // margin-left: 8px;
        font-weight: bold;
        font-size: 12px;
        color: #E9934C;
      }

      .product-type-name {
        margin-top: 7px;
        font-size: 14px;
        color: #080B1A;
        font-weight: lighter;
        line-height: 21px;
      }
    }

    .box-form {
      box-sizing: border-box;
      cursor: pointer;
      padding: 20px 35px 17px 35px;

      &>div>span:first-child {
        color: #7D7F8C;
        font-size: 14px;
        // line-height: 21px;
      }

      &>div {
        margin-bottom: 10px;
      }

      &>div>span:last-child {
        color: #080B1A;
        font-size: 14px;
        // line-height: 21px;
      }
    }

    .box-bottom {
      border-top: 1px solid #ECECEC;
      width: 360px;
      height: 48px;
      display: flex;
      background: #FAFAFA;
      align-items: center;

      &>div {
        width: 25%;
        height: 22px;
        border-right: 1px solid #ECECEC;
        display: flex;
        align-items: center;
        justify-content: center;

        &>div {
          width: 13px;
          height: 13px;
        }
      }

      &>div:first-child>div {
        background: url("~@/assets/icon/edit.png");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &>div:nth-child(2)>div {
        background: url("~@/assets/icon/del.png");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &>div:nth-child(3)>div {
        background: url("~@/assets/icon/improt.png");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &>div:nth-child(4)>div {
        background: url("~@/assets/icon/drvice.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .normal-box:hover {
    background: linear-gradient(to top, #DFEDFF 0%, #FFFFFF 100%);
    box-shadow: 0px 0px 12px 0px rgba(216, 216, 216, 0.5);
    border: 2px solid #367CC5;

    .box-bottom {
      background: #367CC5;

      &>div {
        border-right: 1px solid #78ACD4;
      }

      &>div:first-child>div {
        background: url("~@/assets/icon/edit_1.png");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &>div:nth-child(2)>div {
        background: url("~@/assets/icon/del_1.png");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &>div:nth-child(3)>div {
        background: url("~@/assets/icon/improt_1.png");
        background-size: contain;
        background-repeat: no-repeat;
      }

      &>div:nth-child(4)>div {
        background: url("~@/assets/icon/drvice_1.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .add-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #EBEFF6;

    img {
      width: 38.5px;
      height: 38.5px;
      margin-bottom: 15px;
    }

    div {
      font-size: 16px;
      color: #333333;
      line-height: 22px;
    }
  }
}

.input-item {
  width: 260px;
}

.input-item1 {
  width: 200px;
}

.upload-btn {
  display: flex;
  align-items: center;
  width: 136px;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #AFB9CB;
  font-size: 16px;
  color: #333;
  justify-content: center;
  border-radius: 4px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 11px;
  }
}

.upload-btn:hover,
.upload-btn:active {
  border: 2px solid #367CC5;
  background: #EBEFF6;
}

.right-btns {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 25px;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #AFB9CB;

  &>div {
    width: 47px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .isSelect {
    background: #367CC5;
  }
}

.no-date {
  display: flex;
  margin-top: 30vh;

  img {
    margin: 0 auto;
  }
}

.search-container {
  display: flex;
  align-items: center;
  // justify-content: space-between;
}

.device-homePage {
  box-sizing: border-box;
  // padding: 20px;
}

.form-item {
  display: flex;
  align-items: center;
  margin-right: 25px;

  .search-btn {
    margin-left: 10px;
    cursor: pointer;
    width: 92px;
    height: 36px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    background: #367CC5;
    font-weight: lighter;
    // border: 1px solid #015ee0;
    font-size: 14px;
    color: #FFFFFF;
    font-size: 16px;
  }

  .search-btn:hover,
  .search-btn:active {
    border: 1px solid #367CC5;
    background: #61A4E9;
    // color: #367CC5;
  }
}

.del-container {
  text-align: center;

  &>div:last-child {
    margin-top: 20px;
  }

  &>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.list-container {
  width: calc(88vw);
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;

  .list-item {
    padding: 10px;
  }

  .item-conatiner {
    box-sizing: border-box;
    padding: 20px 30px;
    background: #fff;
  }



  .item-top {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e3e3;

    .item-icon {
      width: 50px;
      height: 50px;

      // background: #015ee0;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .item-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: keep-all;
      width: 16vw;
      margin-left: 15px;
      font-size: 16px;
      color: #015ee0;
      font-weight: 700;
    }
  }

  .item-bottom {
    box-sizing: border-box;
    padding: 16px 4px;

    .bottom-item {
      display: flex;
      align-items: center;
      padding: 8px 0;

      .point {
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background: #d8d8d8;
        margin-right: 10px;
      }

      .bottom-title {
        display: inline-block;
        font-size: 14px;
        color: #7d7f8c;
      }

      .bottom-value {
        font-size: 14px;
        color: #080b1a;
      }
    }
  }
}

.footer-conatiner {

  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn-groups {
  display: flex;
  align-items: center;
}

.reset-btn {
  margin-left: 10px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}

.tables {
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 45px;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 14px;
  line-height: 58px;
}
</style>