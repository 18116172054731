<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination :small="small" :background="background" :current-page="pageParams.pageNum"
      :page-size="pageParams.pageSize" :layout="layout" :page-sizes="pageSizes" :total="total" v-bind="$attrs"
      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import { scrollTo } from "../../utils/scroll-to";
import { reactive, toRefs } from "vue";
export default {
  name: "PagInation",
  setup(props, context) {
    const { autoScroll, limit } = toRefs(props);
    const pageParams = reactive({
      pageNum: 1,
      pageSize: limit.value,
    });
    let { emit } = context;
    const handleSizeChange = (val) => {
      pageParams.pageSize = val;
      const max = Math.ceil(props.total / val)
      if (pageParams.pageNum > max) {
        return
      }
      emit("pageChange", pageParams);
      if (autoScroll) {
        scrollTo(0, 800); // 页面改变 回到顶部
      }
    };
    //改变当前页的页码
    const handleCurrentChange = (val) => {
      pageParams.pageNum = val;
      emit("pageChange", pageParams);
      if (autoScroll) {
        scrollTo(0, 800); // 页面改变 回到顶部
      }
    };
    return {
      pageParams,
      handleSizeChange,
      handleCurrentChange,
    };
  },
  props: {
    total: {
      required: true,
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 11,
    },
    pageSizes: {
      type: Array,
      default() {
        return [11, 25, 50, 100];
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.pagination-container {
  display: flex;
}

.pagination-container.hidden {
  display: none;
}

/deep/ .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
  // background: #2BC0CE linear-gradient(180deg, #57BDBA 0%, #3284AF 100%) !important;
  background: #367CC5;
  // border-radius: 3px !important;
}
</style>
